<template>
  <a
    class="pagination__link"
    :aria-label="ariaLabel"
    @click="handlePageClick"
  >
    {{ page }}
  </a>
</template>

<script>
export default {
  props: {
    page: {
      type: Number,
      required: true
    }
  },
  computed: {
    ariaLabel() {
      return `Go to page ${this.page}`
    }
  },
  methods: {
    handlePageClick() {
      this.$emit('pageClicked', this.page)
    }
  }
}
</script>
