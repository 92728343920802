var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"study-participants"},[_c('PageHeader',{attrs:{"title":"All Participants","back-link":{ text: 'All Studies', route: { name: 'studies' } },"tab-links":[
      {
        text: 'Study Overview',
        route: { name: 'studyOverview', params: { studyId: this.$route.params.studyId } },
        exact: true
      },
      { text: 'Participants', route: { name: 'studyParticipants', params: { studyId: this.$route.params.studyId } } },
      { text: 'Users', route: { name: 'studyUsers', params: { studyId: this.$route.params.studyId } } } ]}}),(_vm.filters.length)?_c('SearchFilters',{attrs:{"filters":_vm.filters},on:{"filter-updated":_vm.updateSearchFilter}}):_vm._e(),_c('SearchBox',{attrs:{"placeholder":"Find a participant","update-on-type":false},on:{"input":_vm.searchStringUpdate}}),(_vm.participantsError && !_vm.isLoading)?_c('EmptyState',{attrs:{"title":"Error Loading Data","subtitle":"Try refreshing this page","image":"error","image-above-text":true,"display-in-table":true}},[_c('el-button',{staticClass:"refresh-page-button",attrs:{"type":"primary"},on:{"click":_vm.refreshPage}},[_vm._v(" Refresh Page ")])],1):_c('div',[(_vm.hasParticipants)?_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],ref:"participantsTable",staticClass:"study-participants-table clickable-rows",class:{ 'no-results': !_vm.hasParticipants },attrs:{"data":_vm.participants,"row-key":"customerDefinedId"},on:{"row-click":_vm.goToParticipant,"sort-change":_vm.updateSort}},[_c('el-table-column',{attrs:{"prop":"customerDefinedSiteId","label":"Site ID","min-width":"100","resizable":false,"sortable":false}}),_c('el-table-column',{attrs:{"prop":"site","label":"Site","min-width":"282","resizable":false,"sortable":"custom"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var column = ref.column;
return [_vm._v(" "+_vm._s(column.label)+" "),_c('Caret',{attrs:{"order":column.order}})]}}],null,false,3958328393)}),_c('el-table-column',{attrs:{"prop":"customerDefinedId","label":"Participant ID","min-width":"282","resizable":false,"sortable":"custom"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var column = ref.column;
return [_vm._v(" "+_vm._s(column.label)+" "),_c('Caret',{attrs:{"order":column.order}})]}},{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('a',{staticClass:"participant-id-link",attrs:{"aria-label":("Go to participant " + (row.customerDefinedId))},on:{"click":function($event){return _vm.goToParticipant(row)}}},[_vm._v(_vm._s(row.customerDefinedId))])]}}],null,false,57048296)}),_c('el-table-column',{attrs:{"prop":"cohortId","label":"Cohort","min-width":"282","resizable":false,"sortable":"custom"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var column = ref.column;
return [_vm._v(" "+_vm._s(column.label)+" "),_c('Caret',{attrs:{"order":column.order}})]}},{key:"default",fn:function(ref){
    var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getCohortName(row.cohortId))+" ")]}}],null,false,3426478914)}),_c('el-table-column',{attrs:{"prop":"status","label":"Status","min-width":"100","resizable":false,"sortable":"custom"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var column = ref.column;
return [_vm._v(" "+_vm._s(column.label)+" "),_c('Caret',{attrs:{"order":column.order}})]}},{key:"default",fn:function(ref){
    var row = ref.row;
return [(row.status)?_c('span',[_vm._v(" "+_vm._s(_vm._f("titleCase")(row.status.replaceAll('_', ' ')))+" ")]):_vm._e()]}}],null,false,1087401347)})],1):(_vm.isLoading)?_c('SkeletonTable',{directives:[{name:"loading",rawName:"v-loading",value:(true),expression:"true"}]}):_c('EmptyState',{attrs:{"title":"No Participants Found","subtitle":_vm.searchString ? 'Try another search term' : 'No participants have been added to this study',"image":"participant","image-above-text":true,"display-in-table":true}},[_c('template',{slot:"subtitle"},[_c('p',[_vm._v(" "+_vm._s(_vm.hasParticipants ? 'Try another search term' : 'No participants have been added to this study')+" "),(_vm.hasSearchFiltersApplied)?_c('a',{on:{"click":_vm.clearSearchFilters}},[_vm._v(" or clear applied filters ")]):_vm._e()])])],2)],1),(_vm.hasParticipants || _vm.isLoading)?_c('Pagination',{attrs:{"total":_vm.pagination.total,"page":_vm.pagination.page,"per-page":_vm.pagination.perPage},on:{"pagechanged":_vm.handlePageChange,"perpage-changed":_vm.handlePerPageChange}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }