<template>
  <div class="pagination">
    <div class="pagination__filters">
      <el-dropdown
        trigger="click"
        placement="bottom-start"
        @command="handleItemsFilter"
        @visible-change="isOpen => isDropdownOpen = isOpen"
      >
        <span class="el-dropdown-link">
          Items per page {{ perPage }}
          <SvgIcon
            :class="{ 'rotate': isDropdownOpen }"
            name="chevron-right"
          />
        </span>
        <el-dropdown-menu
          slot="dropdown"
          class="pagination__filters-dropdown"
        >
          <el-dropdown-item
            v-for="count in countOptions"
            :key="count"
            :command="count"
            :class="{ selected: perPage === count }"
          >
            {{ count }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      {{ /* Adds support for additional filters */ }}
      <slot />
    </div>
    <nav
      class="pagination__links"
      role="navigation"
      aria-label="Pagination Navigation"
    >
      <a
        class="pagination__link prev"
        aria-label="Go to the Previous Page"
        :disabled="isFirstPage"
        @click="goToPrevious"
      >
        <SvgIcon name="chevron-right" />
      </a>

      <PageLink
        v-if="page > 3"
        :page="1"
        class="pagination__link first"
        @pageClicked="goToPage"
      />
      <span
        v-if="page > 4"
        class="pagination__label"
      >
        <span class="ellipsis">&hellip;</span>
      </span>
      <PageLink
        v-for="link in pageLinks"
        :key="link"
        :page="link"
        :class="{active: link == page}"
        @pageClicked="goToPage"
      />
      <span
        v-if="page < totalPages - 3"
        class="pagination__label"
      >
        <span class="ellipsis">&hellip;</span>
      </span>
      <PageLink
        v-if="page < totalPages - 2"
        :page="totalPages"
        class="pagination__link last"
        @pageClicked="goToPage"
      />

      <a
        class="pagination__link next"
        aria-label="Go to the Next Page"
        :disabled="isLastPage"
        @click="goToNext"
      >
        <SvgIcon name="chevron-right" />
      </a>
    </nav>
  </div>
</template>

<script>
import { range } from 'ramda'
import PageLink from './PageLink.vue'

export default {
  components: {
    PageLink
  },
  props: {
    total: {
      type: Number,
      required: true
    },
    page: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      default: 10
    },
    countOptions: {
      type: Array,
      default: () => [10, 25, 50, 100]
    }
  },
  data() {
    return {
      isDropdownOpen: false
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.perPage)
    },
    isFirstPage() {
      return this.page === 1
    },
    isLastPage() {
      return this.page >= this.totalPages
    },
    pageLinks() {
      const firstPage = this.page <= 3 ? 1 : this.page - 2
      const lastPage = Math.min(this.page + 2, this.totalPages) + 1

      return range(firstPage, lastPage)
    }
  },
  methods: {
    goToPage(pageNumber) {
      this.$emit('pagechanged', pageNumber)
    },

    goToPrevious() {
      this.goToPage(this.page - 1)
    },

    goToNext() {
      this.goToPage(this.page + 1)
    },

    handleItemsFilter(itemsPerPage) {
      this.$emit('perpage-changed', itemsPerPage)
    }
  }
}
</script>

<style lang="scss">
  .pagination {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 1rem;

    &__filters {
      min-width: 10rem;
      height: 2rem;
      margin-left: .5rem;
    }

    &__filters-dropdown {
      // Need to add !important inn order to prevent an odd "bump" the occurs due to some
      // conflicting styles within Element UI's popper/dropdown.
      transform: translateY(-.5rem) !important;
    }

    .el-dropdown-link {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: $black;

      svg {
        transform: rotate(90deg);
        transition: transform .3s .2s ease-in-out;

        &.rotate {
          transform: rotate(-90deg);
        }
      }

      &:hover, &:focus {
        color: $dopamine;

        svg {
          fill: $dopamine;
        }
      }
    }

    &__links {
      min-width: 10rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &__link,
    &__label {
      @include text-style('interface', 'small', 'medium')
    }

    &__label {
      user-select: none;

      .ellipsis {
        position: relative;
        top: -.15em;
      }
    }

    &__link {
      position: relative;
      width: 1.5rem;
      padding: .25rem 0;
      color: $black;
      text-align: center;
      text-decoration: none;
      user-select: none;

      &:hover {
        cursor: pointer;
        color: $dopamine;
        @include text-weight('bold');

        svg {
          fill: $dopamine;
        }
      }

      &.active {
        @include text-weight('bold');

        &:after {
          position: absolute;
          content: '';
          width: 100%;
          height: .25rem;
          background-color: $active-pagination;
          bottom: -.15rem;
          left: 0;
        }
      }

      &.prev,&.next {
        width: 2rem;
      }
    }

    svg {
      width: 2rem;
      height: 2rem;
    }

    .prev svg {
      transform: rotateY(180deg)
    }

    &__link[disabled="disabled"] {
      opacity: .25;
      pointer-events: none;
    }
  }
</style>
