<template>
  <ul
    class="tabs"
    :class="orientation"
  >
    <li
      v-for="(link, i) in links"
      :key="i"
    >
      <!-- TODO: Vue-router's router-links do not receive focus from tabbing. Find a fix. -->
      <router-link
        :to="link.route"
        :exact="link.exact"
        :class="generateLinkClass(link)"
      >
        <SvgIcon
          v-if="link.icon"
          :name="link.icon"
        />
        {{ link.text }}
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    /*
     * This component requires an array of links to generate the tab links.
     * An example of a links array and supported properties:
     * links: [
     *  {
     *    text: 'String',    // link text
     *    icon: 'icon-name', // name of icon
     *    exact: false,      // active state for exact route only?
     *    route: {           // the route object normally passed to <router-link>
     *      name: 'routeName',
     *      params: { ...this.$route.params }
     *    }
     *  }
     * ]
     */
    links: {
      type: Array,
      required: true
    },
    orientation: {
      type: String,
      default: 'vertical',
      validator: val => ['vertical', 'horizontal'].includes(val)
    }
  },
  methods: {
    generateLinkClass(link) {
      return link.text.replace(' ', '-').toLowerCase() + '-link'
    }
  }
}
</script>

<style lang="scss">
ul.tabs {
  padding: 0;

  li {
    list-style: none;
  }

  a {
    padding: 1rem;
    color: $black;
    font-size: .875rem;
    line-height: 1rem;
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
      fill: $black;
      height: 1rem;
      width: 1rem;
      margin-right: .5rem;
    }

    &:hover:not(.router-link-active) {
      color: $gaba;

      svg {
        fill: $gaba;
      }
    }

    &.router-link-active {
      position: relative;

      &:before {
        content: '';
        width: 2px;
        height: 2rem;
        background-color: $dopamine;
        position: absolute;
        left: 0;
      }
    }
  }

  &.horizontal {
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    li {
      margin-right: 1.5rem;
    }

    a {
      width: max-content;
      padding: 1rem 0;

      &.router-link-active:before {
        width: 100%;
        height: 2px;
        bottom: 0;
      }
    }
  }
}
</style>
