<template>
  <div class="study-participants">
    <PageHeader
      title="All Participants"
      :back-link="{ text: 'All Studies', route: { name: 'studies' } }"
      :tab-links="[
        {
          text: 'Study Overview',
          route: { name: 'studyOverview', params: { studyId: this.$route.params.studyId } },
          exact: true
        },
        { text: 'Participants', route: { name: 'studyParticipants', params: { studyId: this.$route.params.studyId } } },
        { text: 'Users', route: { name: 'studyUsers', params: { studyId: this.$route.params.studyId } } },
      ]"
    />
    <SearchFilters
      v-if="filters.length"
      :filters="filters"
      @filter-updated="updateSearchFilter"
    />
    <SearchBox
      placeholder="Find a participant"
      :update-on-type="false"
      @input="searchStringUpdate"
    />
    <EmptyState
      v-if="participantsError && !isLoading"
      title="Error Loading Data"
      subtitle="Try refreshing this page"
      image="error"
      :image-above-text="true"
      :display-in-table="true"
    >
      <el-button
        type="primary"
        class="refresh-page-button"
        @click="refreshPage"
      >
        Refresh Page
      </el-button>
    </EmptyState>
    <div v-else>
      <el-table
        v-if="hasParticipants"
        ref="participantsTable"
        v-loading="isLoading"
        class="study-participants-table clickable-rows"
        :class="{ 'no-results': !hasParticipants }"
        :data="participants"
        row-key="customerDefinedId"
        @row-click="goToParticipant"
        @sort-change="updateSort"
      >
        <el-table-column
          prop="customerDefinedSiteId"
          label="Site ID"
          min-width="100"
          :resizable="false"
          :sortable="false"
        />
        <el-table-column
          prop="site"
          label="Site"
          min-width="282"
          :resizable="false"
          sortable="custom"
        >
          <template v-slot:header="{ column }">
            {{ column.label }}
            <Caret :order="column.order" />
          </template>
        </el-table-column>
        <el-table-column
          prop="customerDefinedId"
          label="Participant ID"
          min-width="282"
          :resizable="false"
          sortable="custom"
        >
          <template v-slot:header="{ column }">
            {{ column.label }}
            <Caret :order="column.order" />
          </template>
          <template v-slot="{ row }">
            <a
              class="participant-id-link"
              :aria-label="`Go to participant ${row.customerDefinedId}`"
              @click="goToParticipant(row)"
            >{{ row.customerDefinedId }}</a>
          </template>
        </el-table-column>
        <el-table-column
          prop="cohortId"
          label="Cohort"
          min-width="282"
          :resizable="false"
          sortable="custom"
        >
          <template v-slot:header="{ column }">
            {{ column.label }}
            <Caret :order="column.order" />
          </template>
          <template v-slot="{ row }">
            {{ getCohortName(row.cohortId) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="Status"
          min-width="100"
          :resizable="false"
          sortable="custom"
        >
          <template v-slot:header="{ column }">
            {{ column.label }}
            <Caret :order="column.order" />
          </template>
          <template v-slot="{ row }">
            <span v-if="row.status">
              {{ row.status.replaceAll('_', ' ') | titleCase }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- Show the SkeletonTable before the first load, or when we don't have other participants to show -->
      <SkeletonTable
        v-else-if="isLoading"
        v-loading="true"
      />
      <EmptyState
        v-else
        title="No Participants Found"
        :subtitle="searchString ? 'Try another search term' : 'No participants have been added to this study'"
        image="participant"
        :image-above-text="true"
        :display-in-table="true"
      >
        <template slot="subtitle">
          <p>
            {{ hasParticipants ? 'Try another search term' : 'No participants have been added to this study' }}
            <a
              v-if="hasSearchFiltersApplied"
              @click="clearSearchFilters"
            >
              or clear applied filters
            </a>
          </p>
        </template>
      </EmptyState>
    </div>

    <Pagination
      v-if="hasParticipants || isLoading"
      :total="pagination.total"
      :page="pagination.page"
      :per-page="pagination.perPage"
      @pagechanged="handlePageChange"
      @perpage-changed="handlePerPageChange"
    />
  </div>
</template>

<script>
import SearchBox from '@/components/SearchBox/SearchBox'
import SkeletonTable from '@/components/SkeletonTable/SkeletonTable'
import EmptyState from '@/components/EmptyState/EmptyState'
import PageHeader from '@/components/PageHeader/PageHeader'
import Caret from '@/components/Caret/Caret'
import Pagination from '@/components/Pagination/Pagination'
import SearchFilters from '@/components/SearchFilters/SearchFilters'
import searchFilterFunctionality from '@/components/SearchFilters/SearchFilterFunctionality'
import GET_RESTRICTED_PARTICIPANTS_QUERY from '@/graphql/participants/GetRestrictedParticipantsByStudyQuery.graphql'
import GET_SITES_QUERY from '@/graphql/sites/GetSitesQuery.graphql'
import GET_COHORTS_QUERY from '@/graphql/cohorts/GetStudyCohortsQuery.graphql'
import { ParticipantProtocolStatus } from '@/utils/constants'
import { logError } from '@/utils/logging'
import { path, pathOr, sort } from 'ramda'

export default {
  components: {
    SearchBox,
    SkeletonTable,
    EmptyState,
    PageHeader,
    Caret,
    Pagination,
    SearchFilters
  },
  mixins: [searchFilterFunctionality],
  data() {
    return {
      participants: [],
      participantsError: false,
      sites: [],
      cohorts: [],
      pagination: {
        page: 1,
        perPage: 100,
        total: 0
      },
      sorting: {
        prop: 'id',
        order: 'ascending'
      },
      searchString: ''
    }
  },

  apollo: {
    /*
     * Get all participants associated with this study.
     */
    participants() {
      return {
        query: GET_RESTRICTED_PARTICIPANTS_QUERY,
        // Force this query to always re-fetch over the network, due to pagination, search, and filtering happening
        // on the backend
        fetchPolicy: 'network-only',
        variables() {
          return this.queryVariables
        },
        update: data => {
          // add site name, site customerDefinedId and enrollment status to each participant
          if (path(['getParticipantsRestricted', 'entities'], data)) {
            data.getParticipantsRestricted.entities.forEach(participant => {
              participant.site = this.getSiteName(participant.siteId)
              participant.customerDefinedSiteId = this.getCustomerDefinedSiteId(participant.siteId)
              participant.status = pathOr(null, ['protocols', 0, 'status'], participant)
            })
            this.updatePaginationTotal(data.getParticipantsRestricted.totalCount)
            return data.getParticipantsRestricted.entities
          } else {
            this.updatePaginationTotal(0)
            return []
          }
        },
        result({ data }) {
          // Set whether there was a network error based on whether any results are returned
          this.participantsError = !data.getParticipantsRestricted
        },
        error (error) {
          logError(error, 'StudyParticipants.vue participant query')
          this.participantsError = true
        },
        skip() {
          // need to wait for sites so that default sort on site column works properly
          return !this.sites.length
        }
      }
    },

    // get Sites, store in array by ID so we can pull for table
    sites() {
      return {
        query: GET_SITES_QUERY,
        variables: {
          studyId: this.studyId
        },
        update: data => data.getSites,
        error (error) {
          logError(error, 'StudyParticipants.vue site query')
        },
        skip() {
          return !this.studyId
        }
      }
    },

    // get cohorts to reference in table
    cohorts() {
      return {
        query: GET_COHORTS_QUERY,
        variables: {
          studyId: this.$route.params.studyId,
          withParticipantCount: true
        },
        update: data => data.getStudyCohorts,
        error (error) {
          logError(error, 'StudyParticipants.vue cohort query')
        }
      }
    }
  },

  computed: {
    studyId() {
      return this.$route.params.studyId
    },

    isLoading() {
      return this.$apollo.queries.participants.loading
    },

    hasParticipants() {
      return path(['length'], this.participants) > 0
    },

    queryVariables() {
      const queryVars = {
        studyId: this.studyId,
        sort: `${this.sorting.prop}${this.shortenSortOrder(
          this.sorting.order
        )}`,
        page: this.pagination.page,
        pageSize: this.pagination.perPage,
        search: this.searchString
      }
      this.filters.forEach(filter => {
        // this check avoids sending a bad value for status
        if (filter.selected.value !== '') {
          queryVars[filter.prop] = filter.selected.value
        }
      })
      return queryVars
    }
  },

  watch: {
    /*
     * Once we receive our list of participants, get the visits for each participant
     * For the CTMS, do not rerun filter generation after initial load.
     */
    participants() {
      if (this.filters.length < 1) {
        this.generateFilterOptions()
      }
    }
  },

  methods: {
    searchStringUpdate(event) {
      this.searchString = event
    },

    updateSort({ prop, order }) {
      if (order) {
        this.sorting = {
          prop: prop === 'cohortId' ? 'cohort' : prop,
          order: order
        }
      } else {
        this.sorting = {
          prop: 'id',
          order: null
        }
      }
    },

    shortenSortOrder(order) {
      switch (order) {
        case 'ascending':
          return ':asc'
        case 'descending':
          return ':desc'
        default:
          return ''
      }
    },

    /**
     * Takes a siteId and returns the matching site from our list of sites.
     *
     * @param {number} siteId
     * @returns {object} site
     */
    findSite(siteId) {
      if (!siteId || !this.sites) return ''
      return this.sites.find(_site => _site.id === siteId)
    },

    /**
     * Takes a siteId and returns the site name from the matching site.
     *
     * @param {number} siteId
     * @returns {string} the name of the site
     */
    getSiteName(siteId) {
      const site = this.findSite(siteId)
      return site ? site.name : ''
    },

    /**
     * Takes a siteId and returns a customerDefinedId for the matching site.
     *
     * @param {number} siteId
     * @returns {number} a customerDefinedId for the site
     */
    getCustomerDefinedSiteId(siteId) {
      const site = this.findSite(siteId)
      return site ? site.customerDefinedId : ''
    },

    getCohortName(cohortId) {
      if (!cohortId || !this.cohorts) return ''
      const cohort = this.cohorts.find(_cohort => _cohort.id === cohortId)
      return cohort ? cohort.name : ''
    },

    goToParticipant(row) {
      this.$router.push({
        name: 'participantDetails',
        params: {
          participantId: row.id
        }
      })
    },

    getLastVisit(visits) {
      if (visits.length > 0) {
        const visitsInProgress = visits.filter(
          visit =>
            visit.visitInstance && visit.visitInstance.status === 'in_progress'
        )
        if (visitsInProgress.length > 0) {
          return visitsInProgress[0].visitTemplate.name
        } else {
          const pastVisits = visits.filter(
            visit =>
              visit.visitInstance && visit.visitInstance.status === 'complete'
          )
          if (pastVisits.length > 0) {
            return pastVisits[pastVisits.length - 1].visitTemplate.name
          }
        }
      }
      return '—' // em-dash
    },

    /*
     * Generate a site, cohort, & status filter from the participants returned.
     * The filter is added with unique options only, and if there exists more than 1 possible selection.
     */
    generateFilterOptions() {
      const availableFilters = []

      // Create an array of all unique cohorts
      // A Set object lets you store unique values of any type, it is convenient for storing unique values.
      const uniqueSites = Array.from(new Set(this.sites.map(site => site.id)))
      if (uniqueSites.length > 1) {
        sort(uniqueSites)
        // map the unique statuses into option objects
        const siteOptions = this.buildOptions(uniqueSites, siteId => this.getSiteName(siteId))
        // add an option when to reset to no filter
        siteOptions.unshift({ name: 'All Sites', value: '' })
        // add a filter using the available options
        availableFilters.push(this.buildFilter('Site', 'siteId', siteOptions))
      }

      // Get only cohorts that have participants in them.
      const uniqueCohorts = this.cohorts.filter(cohort => cohort.participantCount > 0).map(cohort => cohort.id)
      if (uniqueCohorts.length > 1) {
        sort(uniqueCohorts)
        const cohortOptions = this.buildOptions(uniqueCohorts, cohortId => this.getCohortName(cohortId))
        cohortOptions.unshift({ name: 'All Cohorts', value: '' })
        availableFilters.push(this.buildFilter('Cohort', 'cohortId', cohortOptions))
      }

      const uniqueStatuses = [
        ParticipantProtocolStatus.EXCLUDED,
        ParticipantProtocolStatus.SCREEN_FAILED,
        ParticipantProtocolStatus.WITHDREW,
        ParticipantProtocolStatus.ENROLLED,
        ParticipantProtocolStatus.PENDING
      ]

      if (uniqueStatuses.length > 1) {
        sort(uniqueStatuses)
        const statusOptions = this.buildOptions(uniqueStatuses,
          value => value.replaceAll('_', ' '))
        statusOptions.unshift({ name: 'All Statuses', value: '' })
        availableFilters.push(this.buildFilter('Status', 'status', statusOptions))
      }

      this.filters = availableFilters
    },

    /*
     * When a page is changed, update our query
     */
    handlePageChange(newPage) {
      this.pagination.page = newPage
    },

    /*
     * When the perPage value is modified, update our query
     */
    handlePerPageChange(newPerPage) {
      this.pagination.perPage = newPerPage
      this.handlePageChange(1) // reset to page 1
    },

    /*
     * Receives a new total count to use for pagination calculations
     */
    updatePaginationTotal(newTotalCount) {
      this.pagination.total = newTotalCount
    },

    refreshPage() {
      document.location.reload()
    }
  }
}
</script>

<style lang="scss">
.study-participants-table {
  margin-bottom: 1rem;

  .participant-id-link {
    text-decoration: none;
    color: $dopastat;
  }

  &.el-table--fit {
    border: 1px solid $axon;
  }

  tbody tr {
    td {
      cursor: pointer;
    }

    .cell {
      word-break: break-word;
    }

    &:last-child td {
      border-bottom: 0;
    }
  }

  &.no-results {
    border-bottom: none;
    margin-bottom: 0;

    .el-table__body-wrapper {
      display: none;
    }
  }
}

// overwrite base table styles
#app .el-table.study-participants-table td:first-child {
  @include text-weight('medium');
}
</style>
