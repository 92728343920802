<template>
  <header
    class="header"
    :class="{ 'header--ctms': isCTMSActive && $route.name !== 'readOnlyFormDetails' }"
  >
    <el-button
      v-if="backLink"
      type="ghost"
      class="back-link"
      @click="backTo"
    >
      <SvgIcon
        name="chevron-right"
        class="flip"
      />
      {{ backLink.text }}
    </el-button>
    <div
      v-if="$slots.subtitle || subtitle"
      class="header__subtitle"
    >
      <slot name="subtitle">
        <h2>{{ subtitle }}</h2>
      </slot>
    </div>
    <div class="header__title-wrap">
      <div class="header__title">
        <slot name="title">
          <h1 v-if="title">
            {{ title }}
          </h1>
        </slot>
      </div>
      <div class="header__actions">
        <slot name="actions" />
      </div>
    </div>
    <slot />
    <TabNavigation
      v-if="tabLinks.length"
      orientation="horizontal"
      :links="tabLinks"
    />
  </header>
</template>

<script>
import TabNavigation from '@/components/TabNavigation/TabNavigation'
import detectModule from '@/mixins/detectModule'

export default {
  name: 'PageHeader',
  components: {
    TabNavigation
  },
  mixins: [detectModule],
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    backLink: {
      type: Object,
      default: null
    },
    tabLinks: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    backTo() {
      if (this.backLink.route) {
        this.$router.push(this.backLink.route)
      } else {
        // fallback to index
        this.$router.push({ name: 'index' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// scoped styles here because of the generic classname
.header {
  padding-bottom: 1.5rem;
  //margin-bottom: 1.5rem;
  background-color: transparent;
  border-bottom: 1px solid $cortex;

  &__title {
    margin-right: 1rem;
    word-break: break-word;
  }

  &__subtitle {
    color: $black;
  }

  h1 {
    margin: 0px;
    @include text-style('title', 'large', 'bold');
  }

  h2 {
    margin: 0px;
    color: $hillcock;
    @include text-style('interface', 'small', 'regular');
  }

  &__title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__actions {
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;

    > * {
      margin-left: .5rem;
    }
  }

  // CTMS Header Styles
  &--ctms {
    margin: -1.5rem; // override padding-wrap
    margin-bottom: 1.5rem;
    padding: .5rem 1rem;
    background-color: $white-matter;

    h1 {
      @include text-style('title', 'large', 'bold');
    }

    .header__title-wrap {
      &:only-child {
        margin: 1rem 0;
      }

      &:last-child {
        margin-bottom: 1rem;
      }
    }

    .tabs {
      margin-bottom: -.5rem; // offset by the padding to create the active state border
    }
  }

  @media screen and (max-width: $breakpoint-mobile) {
    &__title-wrap {
      flex-wrap: wrap;
    }

    &__actions {
      margin-top: 1rem;
    }
  }
}
</style>
